import jQuery from "jquery";

let currentEditObject = null;

function showModal($, modalId) {
    $(modalId).removeClass('hidden');
    $('body').addClass('no-scroll');
}

function hideModal($, modalId) {
    $(modalId).addClass('hidden');
    $('body').removeClass('no-scroll');
}



function addRecurringRecord($container, $clone, recurring, even, $, page) {
    $clone.addClass($clone.data('class-' + (even ? 'odd' : 'even')));

    $clone.addClass('page-' + page);
    $clone.addClass('recurring-row');

    $clone.find('.recurring-text-id').html(recurring.id);
    $clone.find('.recurring-text-created_at').html(recurring.created_at);
    $clone.find('.recurring-text-method').html(recurring.method);
    $clone.find('.recurring-text-amount').html(recurring.amount);
    $clone.find('.recurring-text-currency').html(recurring.currency);
    $clone.find('.recurring-text-currency_amount').html(recurring.currency + " " + recurring.amount);
    $clone.find('.recurring-text-type').html(recurring.type);
    $clone.find('.recurring-text-recurring_id').html(recurring.recurring_id);
    $clone.find('.recurring-text-status').html(recurring.status_translated);
    $clone.find('.recurring-text-date_next').html(recurring.date_next);
    $clone.find('.recurring-text-date_until').html(recurring.date_until);

    if(recurring.status === 'Successful') {
        $clone.find('.edit-recurring').click(function(event) {
            event.preventDefault();

            currentEditObject = recurring;

            $('#edit_recurring').find('#currency').attr('disabled', true);
            $('#edit_recurring').find('#currency').val(recurring.currency);
            $('#edit_recurring').find('#amount').val(recurring.amount);
            $('#edit_recurring').find('#form-select-update-next-donation').val(recurring.recurring_day);
            $('#edit_recurring').find('#expiry_month').val(recurring.expiry_month);
            $('#edit_recurring').find('#expiry_year').val(recurring.expiry_year);

            console.log(recurring);

            showModal($,'#edit_recurring');


        });
        $clone.find('.stop-recurring').click(function(event) {
            event.preventDefault();

            currentEditObject = recurring;

            $('#stop_recurring').find('#currency').attr('disabled', true);
            $('#stop_recurring').find('#currency').val(recurring.currency);
            $('#stop_recurring').find('#amount').val(recurring.amount);
            $('#stop_recurring').find('#form-select-update-next-donation').val(recurring.recurring_day);
            $('#stop_recurring').find('#expiry_month').val(recurring.expiry_month);
            $('#stop_recurring').find('#expiry_year').val(recurring.expiry_year);

            console.log(recurring);

            showModal($,'#stop_recurring');


        });

    }
    else {
        $clone.find('.edit-recurring').hide();
        $clone.find('.stop-recurring').hide();
    }


    $container.append($clone);
}

function setUpRecurringEditDialogSubmit($, setRecurrings, token) {
    $('#edit_recurring').find('.btn-ok').click(function() {
        $.post('/donations/update-recurring.json', {
            _token: token,
            id: currentEditObject.id,
            amount: $('#edit_recurring').find('#amount').val(),
            recurring_day: $('#edit_recurring').find('#form-select-update-next-donation').val(),
            expiry_month: $('#edit_recurring').find('#expiry_month').val(),
            expiry_year: $('#edit_recurring').find('#expiry_year').val(),
        }, function(data) {
            setRecurrings(data);
            hideModal($, '#edit_recurring');
        }, 'json')
    });

    $('#stop_recurring').find('.btn-ok').click(function() {
        $.post('/donations/stop-recurring.json', {
            _token: token,
            id: currentEditObject.id,
        }, function(data) {
            setRecurrings(data);
            hideModal($, '#stop_recurring');
        }, 'json')
    });
}

export { addRecurringRecord, setUpRecurringEditDialogSubmit }
