import jQuery from "jquery";

function createPaging($after, nr_of_pages, callback) {
    let $container = jQuery('<div>');
    $container.addClass('m-3 pt-2');

    function activateLink($link) {
        jQuery('.paging-link').removeClass('font-bold bg-gray-100 rounded');
        jQuery('.page-link-' + $link.data('page')).addClass('font-bold bg-gray-100 rounded');
    }

    for(let i = 1; i <= nr_of_pages; i++) {
        let $link = jQuery('<div>');
        $link.data('page', i);

        $link.addClass('cursor-pointer p-3 inline-block paging-link rounded hover:bg-gray-100 page-link-' + i);
        $link.append(i)

        $link.click(function() {
            activateLink(jQuery(this));
            callback(jQuery(this).data('page'));
        });

        $container.append($link);
    }

    activateLink($container.find('.paging-link:first-of-type'))

    $after.append($container);
}


export { createPaging };
