import jQuery from "jquery";
import Swup from "swup";
import {addRecurringRecord, setUpRecurringEditDialogSubmit} from "@/front/recurring";
import {createPaging} from "@/front/components/paging";
// import {updateBlueSnapToken} from "@/front/bluesnap";

const swup = new Swup({
    animateHistoryBrowsing: true,
    linkSelector: [
        'a[href^="/"]',
        'a[href^="https://' + document.location.hostname + '/"]',
    ].join(', '),
    containers: ['#swup', '.language-selector', 'footer'],
    requestHeaders: {
        "X-Requested-With": "swup", // so we can tell request comes from swup
        Accept: "text/html, application/xhtml+xml, application/json" // to explicitly define what response we are expecting
    }});

function onPageLoad(callback) {
    jQuery(document).ready(function($) {
        callback($);
    })
}

function onPageSwap(callback) {
    swup.on('contentReplaced', function() {
        callback(jQuery);
    });
}

function onPageLoadAndSwap(callback) {
    onPageLoad(callback);
    onPageSwap(callback);
}

function goToPage(url) {
    swup.loadPage({
        url: (url),
        method: 'GET'
    });
}

let user_data = {};
let recurrings = [];
let donations = [];

function updateData(data, type) {
    if(typeof data == 'undefined' || data == null) return;

    Object.keys(data).forEach(key => {
        let value = data[key];
        jQuery('.' + type + '-text-' + key).text(value);
        jQuery('.' + type + '-val-' + key + ' input').val(value);
        jQuery('.' + type + '-val-' + key + ' select').val(value);
        jQuery('.' + type + '-val-' + key + ' select').trigger('change');
        jQuery('.' + type + '-checked-' + key + ' input').each(function() {
            if(jQuery(this).val() == value) {
                jQuery(this).attr('checked', true);
            }
            else {
                jQuery(this).attr('checked', false);
            }
        });
    });
}

function parseUserData() {
    if(typeof user_data.id == 'undefined') {
        jQuery('.when-logged-out').removeClass('hidden');
        jQuery('.when-logged-in').addClass('hidden');
    }
    else {

        updateData(user_data.donor, 'donor');
        updateData(user_data.address, 'address');
        updateData(user_data.email, 'email');
        updateData(user_data.phone, 'phone');

        jQuery('.when-logged-in').removeClass('hidden');
        jQuery('.when-logged-out').addClass('hidden');
    }
    isValidPage();
}

function updateUserData(data) {
    user_data = data;
    console.log(data);
    parseUserData();
}

function isLoggedIn() {
    return typeof user_data.id != 'undefined';
}

onPageLoad(function($) {
    $.get('/user/current.json', function(data) {
        if(typeof data.redirect !== 'undefined') {
            document.location.href = data.redirect;
            return;
        }
        updateUserData(data);

        // updateBlueSnapToken(data.bluesnap);
        if(isLoggedIn()) {
            onLogin();
        }
    });

    onPageSwap(parseUserData);
});

function getValidPath(path) {
    switch(path) {
        case '/' :
        case '' :
        case '/login.html' :
        case '/register.html' :
        case '/forgot-password.html' :
            if(isLoggedIn()) {
                return import.meta.env.VITE_APP_TYPE === 'priyan' ? '/login/donate.html' :  '/login/my-info.html'
            }
            break;
        case '/login/donate.html' :
        case '/login/my-donations.html' :
        case '/login/my-recurrings.html' :
        case '/login/my-info.html' :
        case '/login/change-password.html' :
            if(!isLoggedIn()) {
                return '/login.html';
            }
            break;
    }
    return path;
}

function isValidPage() {
    let path = document.location.pathname;
    let language = '';
    let valid_path = '';

    if(path.match(/^\/[A-Za-z]{2}\//)) {
        language = path.substring(0, 3);
        path = path.substring(3);
    }

    valid_path = getValidPath(path);

    if(path != valid_path) {
        goToPage(language + valid_path);
    }
}

function donationPage(page) {
    jQuery('.payment-row').hide();
    jQuery('.payment-row.page-' + page).show();
}

function setDonationsInInterface() {
    jQuery('.has-donations').each(function() {
        let $container = jQuery(this);
        let template = $container.html();

        $container.html('');
        if(donations.length == 0) {
            $container.html('<tr><td colspan="5" class="px-4 py-3">You do not have any donations.</td></tr>');
        }
        else {
            let page = 1;
            for (let i = 0; i < donations.length; i++) {
                let $clone = jQuery(template);
                let donation = donations[i];
                page = parseInt(i / 10) + 1;

                $clone.addClass($clone.data('class-' + (i % 2 ? 'odd' : 'even')));
                $clone.addClass('page-' + page);
                $clone.addClass('payment-row');

                if(!donation.recurring_id) {
                    $clone.addClass('no-recurring');
                }

                $clone.find('.donation-text-id').html(donation.id);
                $clone.find('.donation-text-created_at').html(donation.created_at);
                $clone.find('.donation-text-method').html(donation.method);
                $clone.find('.donation-text-amount').html(donation.amount);
                $clone.find('.donation-text-currency').html(donation.currency);
                $clone.find('.donation-text-currency_amount').html(donation.currency + " " + donation.amount);
                $clone.find('.donation-text-type').html(donation.type);
                $clone.find('.donation-text-recurring_id').html(donation.recurring_id ? donation.recurring_id : '...');
                $clone.find('.donation-text-status').html(donation.status);

                $container.append($clone);
            }

            donationPage(1);
            createPaging($container.closest('.with-paging'), page, donationPage);
        }




    })
}

// let fncGetToken = function() {
//     return
// }

function recurringPage(page) {
    jQuery('.recurring-row').hide();
    jQuery('.recurring-row.page-' + page).show();
}

function setRecurringsInInterface() {
    jQuery('.has-recurrings').each(function() {
        let $container = jQuery(this);
        let template = $container.html();

        $container.html('');

        if(recurrings.length == 0) {
            $container.html('<tr><td colspan="5" class="px-4 py-3">You do not have any monthly donations.</td></tr>');
        }
        else {
            let page = 1;
            for(let i = 0; i < recurrings.length; i++) {
                page = parseInt(i / 10) + 1;
                addRecurringRecord($container, jQuery(template), recurrings[i], i % 2, jQuery, page);
            }
            recurringPage(1);
            createPaging($container.closest('.with-paging'), page, recurringPage);
        }


    });

    setUpRecurringEditDialogSubmit(jQuery, setRecurrings, user_data.csrf);

}

onPageSwap(function($) {
    setDonationsInInterface();
    setRecurringsInInterface();
});

function getUserData() {
    return user_data;
}

function loadDonations() {
    jQuery.get('/user/donations.json', function(data) {
        donations = data.donations;
        setDonationsInInterface();
    }, 'json')
}

let setRecurrings = function(data) {
    recurrings = data.recurrings;

    navigateTo('/login/my-recurrings.html');
}

function loadRecurrings() {
    jQuery.get('/user/recurrings.json', function(data) {
        recurrings = data.recurrings;
        setRecurringsInInterface();
    }, 'json')
}

function onLogin() {
    loadDonations();
    loadRecurrings();
}

onPageSwap(function() {
    isValidPage();
});

function navigateTo(url) {
    swup.loadPage({
        url: url, // URL of request (defaults to current url)
        method: 'GET' // method of request (defaults to "GET")
    });
}

export { onPageLoad, onPageSwap, onPageLoadAndSwap, goToPage, swup, user_data, parseUserData, updateUserData, isLoggedIn, getValidPath, onLogin, navigateTo, loadDonations, loadRecurrings, setRecurrings, getUserData };
